<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.bookingBtnLabel') }}</h3>
    <div>
      <input type="text" class="flix-form-control" v-model="startBtn" @change="changeStartBtn">
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      startBtn: this.data.startBtn
    }
  },
  watch: {
  },
  methods: {
    changeStartBtn () {
      this.callback(this.startBtn)
    }
  },
  mounted () {
    if (!this.startBtn) {
      this.startBtn = this.$t('message.startBtnTxt')
    }
  },
  computed: {

  }
}
</script>
